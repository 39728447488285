(function($) {
  $(window).on('resize', function() {
    showContent();
  });

  const showContent = function() {
    const width = $('body').width();

    width >= 960 && !$('main').hasClass('show')
      ? $('main').toggleClass('show') &&
        $('.sidebar-primary--content').toggleClass('show') &&
        $('.sidebar-primary--menu-btn').toggleClass('collapsed')
      : null;
  };
})(jQuery);
